import React from "react";
import Container from "../components/container";
import Layout from "../components/layouts/main-layout";
import { StaticImage } from "gatsby-plugin-image";
import FaqSection from "../components/faqsection";
import Seo from "../components/seo";
import Button from "../components/button";
import RentalTable from "../components/rental-table";
import rentalRates from "../data/rental-rates";

const Verleih = () => {
  return (
    <Layout>
      <Seo
        title="Surf Material Verleih an der Surfschule auf Langeoog"
        description="Die Surfschule bietet Surf Material Verleih für Anfänger und Fortgeschrittene im Kitesurfen, Windsurfen, Wingsurfen, Wellenreiten und SUP Fahren an."
      />
      <StaticImage
        src="../images/heroimages/tidens-verleih-header.jpg"
        alt="Surf Material Verleih bei Tidens Surfhuus"
        width={2048}
        height={680}
        quality={90}
        className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
        objectFit="cover"
      />
      <div className="bg-secondary rounded-t-3xl relative top-[-1.5rem]">
        <Container>
          <h1>Verleih</h1>
          <p>
            Wenn du bereits erfahrener Surfer bist, kannst du dir bei uns auch
            das neueste Material ausleihen. Voraussetzung: Du hast einen Kurs
            bei uns besucht oder verfügst über eine VDWS/IKO-Lizenz oder
            Ähnliches. Bei uns bekommst du hochwertiges Material für die
            Sportarten Windsurfen, Kitesurfen, Wingsurfen, Wingfoilen,
            Wellenreiten und Stand-Up Paddling (SUP). Weitere Informationen
            erhältst du auf der Seite der jeweiligen Sportart oder persönlich
            vor Ort an unserer Station am Weststrand von Langeoog.
          </p>
          <p>
            Und wenn dir im Rahmen des Verleihs ein Kite, ein Board oder ein
            Segel so gut gefallen hat, dass du es am liebsten gleich behalten
            möchtest, sprich uns gerne an!
          </p>

          <RentalTable title="Windsurfen" rates={rentalRates.windsurf} />
          <RentalTable title="Kitesurfen" rates={rentalRates.kitesurf} />
          <RentalTable title="Wellenreiten" rates={rentalRates.surf} />
          <RentalTable
            title="Wingsurfen / Wingfoilen"
            rates={rentalRates.wing}
          />
          <RentalTable title="SUP" rates={rentalRates.sup} />
          <RentalTable title="Neopren & Sonstiges" rates={rentalRates.other} />

          <h3 className="mt-5">Unser Material</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <h4>Windsurfen</h4>
              <strong>Segel:</strong> North X Over, Severne Gator, GA IQ
              (Gaastra)
              <br />
              <strong>Boards:</strong> Tabou Rocket 145l, Tabou 3S 116l,
              Anfänger- und Aufsteiger-Boards von 160 - 240 Liter
            </div>
            <div>
              <h4>Wingfoilen</h4>
              <strong>Foil:</strong> North Foil Sets
              <br />
              <strong>Wings:</strong> North Nova, Vayu V3, Airush Freewing GO
              <br />
              <strong>Wingfoilboards:</strong> Vayu 180l & 90l, Tabou Pocket Air
              117l
            </div>
            <div>
              <h4>Wellenreiten</h4>
              <strong>Softboards</strong> in den Größen 5’2 - 9’0
              <br />
              <strong>Hardboards</strong> von Fish bis Longboard in
              verschiedenen Größen
              <br />
            </div>
            <div>
              <h4>Kitesurfen</h4>
              <strong>Kites:</strong> North Reach, Carve, Orbit & Flysurfer
              Hybrid
              <br />
              <strong>Twintips:</strong> North Atmos, Astra, Trace, Prime,
              Flysurfer Stage
              <br />
              <strong>Waveboards:</strong> North Cross
            </div>
            <div>
              <h4>Stand-Up Paddling</h4>
              Starboard iGO inflatable 10’8 & 11’2
            </div>
            <div>
              <h4>Neoprenanzüge</h4>
              Xcel & Mystic & Prolimit in 3/4 & 5/4 und Shortys
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <h3 className="mt-5">VDWS SafetyTool</h3>
              <p>
                In den meisten Haftpflichtversicherungen sind Schäden an
                geliehenen Sportgeräten NICHT im Versicherungsschutz enthalten.
                Daher empfehlen wir dir den Abschluss einer gesonderten
                Versicherung, wie zum Beispiel dem „VDWS Safety Tool“, einer
                speziell auf Wassersport zugeschnittenen Versicherung.
              </p>
              <div className="mt-4 mb-4">
                <Button to="/vdws-versicherung" btntype="secondary" />
              </div>
            </div>
            <div>
              <h3 className="mt-5">Shop</h3>
              <p>
                Wir verkaufen sowohl neues als auch gebrauchtes Material all
                unserer Marken. Wenn du dich also mit eigenem Material
                ausstatten möchtest oder dich für ein neues Modell
                interessierst, bist du bei uns genau richtig. Wir verkaufen
                regelmäßig unser gebrauchtes Schulungsmaterial, erstellen dir
                aber auch gerne ein Angebot für neues Material (Neoprenanzüge,
                Trapeze, Kites, Segel, Boards etc.). Eine kleine Auswahl findest
                du bereits in unserem Onlineshop
              </p>
              <p>
                <strong>Kleines Dankeschön:</strong> Als Kursteilnehmer erhältst du beim Kauf von
                Material sogar Rabatt. Sprich uns gerne direkt vor Ort an oder
                melde dich über das Kontaktformular bei uns.
              </p>
              <div className="mt-4 mb-4">
                <Button
                  external="true"
                  to="https://tidens-shop.de"
                  btntype="secondary"
                  title="Tidens Onlineshop"
                />
              </div>
            </div>
          </div>
          <h3 className="mt-6">Häufige Fragen</h3>
          <FaqSection sport="verleih" />
        </Container>
      </div>
    </Layout>
  );
};

export default Verleih;
