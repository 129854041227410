import React from "react";

const RentalTable = ({ title, rates}) => {

  return (
    <div className="mt-4 overflow-auto md:overflow-hidden">
    <table className="table-fixed w-full">
      <thead>
        <tr>
          <th className="text-left border-b border-primary">
            {title}
          </th>
          <th className="border-b border-primary w-20">1h</th>
          <th className="border-b border-primary w-20">2h</th>
        </tr>
      </thead>
      <tbody>
        {rates.map((rate) => {
          return (
            <tr key={rate.name}>
              <td className="py-1">{rate.name}</td>
              <td className="text-center">{rate.priceOne}€</td>
              <td className="text-center">{rate.priceTwo}€</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
  );
};

export default RentalTable;