const windsurf = [
  {
    name: "Anfängerboard & Rigg",
    priceOne: 20,
    priceTwo: 35,
  },
  {
    name: "Funboard & Performance Rigg",
    priceOne: 45,
    priceTwo: 80,
  },
  {
    name: "Funboard",
    priceOne: 25,
    priceTwo: 45,
  },
  {
    name: "Performance Rigg",
    priceOne: 25,
    priceTwo: 45,
  }
];


const kitesurf = [
  {
    name: "Kite, Bar & Twintip Board",
    priceOne: 45,
    priceTwo: 80,
  },
  {
    name: "Kite & Bar",
    priceOne: 35,
    priceTwo: 65,
  },
  {
    name: "Twintip",
    priceOne: 15,
    priceTwo: 25,
  },
  {
    name: "Waveboard",
    priceOne: 20,
    priceTwo: 35,
  },
  {
    name: "Trainerkite",
    priceOne: 5,
    priceTwo: 10,
  }
];

const surf = [
  {
    name: "Softboard",
    priceOne: 15,
    priceTwo: 25,
  },
  {
    name: "Hardboard",
    priceOne: 20,
    priceTwo: 35,
  }
];

const wing = [
  {
    name: "Wing & Anfängerboard",
    priceOne: 25,
    priceTwo: 45,
  },
  {
    name: "Wing & Foilboard",
    priceOne: 45,
    priceTwo: 80,
  },
  {
    name: "Wing",
    priceOne: 15,
    priceTwo: 25,
  },
  {
    name: "Foilboard",
    priceOne: 35,
    priceTwo: 65,
  },

];

const sup = [
  {
    name: "SUP & Paddel",
    priceOne: 20,
    priceTwo: 35,
  },
  {
    name: "Extra Paddel",
    priceOne: 5,
    priceTwo: 10,
  }
];

const other = [
  {
    name: "Neopren",
    priceOne: 10,
    priceTwo: 15,
  },
  {
    name: "Shorty",
    priceOne: 5,
    priceTwo: 8,
  },
  {
    name: "Neopren Kids",
    priceOne: 5,
    priceTwo: 8,
  },
  {
    name: "Trapez",
    priceOne: 5,
    priceTwo: 10,
  },
  {
    name: "Helm (gratis)",
    priceOne: 0,
    priceTwo: 0,
  },
  {
    name: "Skimboard",
    priceOne: 5,
    priceTwo: 10,
  },
  {
    name: "Kajak 1er",
    priceOne: 20,
    priceTwo: 35,
  },
  {
    name: "Kajak 2er",
    priceOne: 30,
    priceTwo: 55,
  },
  {
    name: "Bodyboard",
    priceOne: 5,
    priceTwo: 10,
  },
  {
    name: "Landboard",
    priceOne: 10,
    priceTwo: 15,
  },
]

const rentalRates = {
  windsurf,
  kitesurf,
  surf,
  wing,
  sup,
  other
}

export default rentalRates;